import React from "react";
import { Link } from "react-router-dom";

// the home page of the website

const Home = () => {
  return (
    <div>
      <h1>Welcome to the home page</h1>
      <p>This is the home page of the website, you can navigate to other pages using the links below</p>
      <ul>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </div>
  );
};

export default Home;