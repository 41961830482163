import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react";
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../index.css';
import Home from './Home';

function App() {
  return (
    <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>

          {/* this is the utility part of the website... it handles analytics and some speed insights from vercel and all */}
          <Analytics />
          <SpeedInsights />
        </div>
    </Router>
  );
}

export default App;
